<template>
  <div>
    <div class="login">
      <!-- <div class="logo"><img src="@/assets/img/login/logo.png" alt="" /></div> -->
      <router-view></router-view>
      <div class="footer-box">
        <p>联系邮箱：info@wecaredigital.com.cn 联系电话：010-67935508</p>
        <p>©2018-2038 北京为开数字科技有限公司 京ICP备18055023号-1</p>
        <ul class="code-list">
          <li>
            <div class="code"><img src="@/assets/erweima-gzh.jpg" alt="" /></div>
            <p>微信公众号</p>
          </li>
          <li>
            <div class="code"><img src="@/assets/erweima-wx.jpg" alt="" /></div>
            <p>微 信 咨 询</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="bottom-box"></div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.login {
  position: relative;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $deepPrimaryColor;
  img {
    width: 100%;
  }
  .logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 42.7%;
    max-width: 820px;
  }

  .footer-box {
    width: 980px;
    position: absolute;
    left: 50%;
    bottom: -2vh;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    p {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      text-align: center;
    }
    .code-list {
      position: absolute;
      right: -60px;
      bottom: -24px;
      display: flex;
      .code {
        width: 100px;
        height: 100px;
      }
      li {
        & + li {
          margin-left: 24px;
        }
      }
    }
  }
}
.bottom-box {
  background: $deepPrimaryColor;
  height: 8vh;
  width: 100%;
}
</style>
